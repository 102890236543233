import logo from './logo.svg';
import './App.css';
import MaintenancePage from './MaintenancePage';

function App() {
  return (
    <MaintenancePage />
  );
}

export default App;
