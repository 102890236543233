// ThunderstormRain.js

import React, { useEffect } from 'react';
import './ThunderstormRain.css';

const ThunderstormRain = () => {
  useEffect(() => {
    const generateRain = () => {
      const container = document.getElementById('thunderstorm-rain-container');
      if (!container) {
        console.error('Thunderstorm rain container not found.');
        return;
      }

      const numberOfRaindrops = 100;
      for (let i = 0; i < numberOfRaindrops; i++) {
        const raindrop = document.createElement('div');
        raindrop.className = 'raindrop';
        raindrop.style.left = `${Math.random() * 100}%`; // Set random horizontal position
        const delay = Math.random() * 5; // Randomize delay for staggered raindrop appearance
        raindrop.style.animationDelay = `${delay}s`;
        container.appendChild(raindrop);
      }
    };

    generateRain();

    // Cleanup function to remove raindrops when component unmounts
    return () => {
      const container = document.getElementById('thunderstorm-rain-container');
      if (container) {
        container.innerHTML = ''; // Remove all raindrops
      }
    };
  }, []);

  return <div id="thunderstorm-rain-container" className="thunderstorm-rain-container"></div>;
};

export default ThunderstormRain;
