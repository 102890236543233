import React from 'react';
import './DullAnimation.css'; // Import CSS file for styling

const DullAnimation = () => {
  return (
    <div className="dull-animation">
      {/* Your dull animation content goes here */}
      {/* For example, you can display a sad emoji */}
      😔 
    </div>
  );
};

export default DullAnimation;
