import React from 'react';
import Countdown from 'react-countdown';
import './MaintenancePage.css';
import TicTacToe from './TicTacToe';
import ThunderstormRain from './ThunderstormRain';
import Game from './dino-game';

class MaintenancePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTicTacToe: true
        };
    }

    componentDidMount() {
        const randomBoolean = Math.random() < 0.5;
        this.setState({ showTicTacToe: randomBoolean });
    }

    render() {
        const { showTicTacToe } = this.state;
        return (
            <div className="maintenance-container">
                <div className="maintenance-content">
                    <h1 className="maintenance-title">Flocci Under Maintenance 🌪️</h1>

                    <ThunderstormRain />
                    <p className="maintenance-message">This website is currently undergoing maintenance!</p>
                    <div className="countdown-container">
                        <p className="countdown-text">Estimated time until back online:</p>
                        <div className="countdown-timer">
                            <Countdown date={Date.now() + 600000000} />
                        </div>
                    </div>
                    {showTicTacToe ? (
                        <div className="tic-tac-toe-container">
                            <TicTacToe />
                        </div>
                    ) : (
                        <div className="dino-game-container">
                            <Game />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default MaintenancePage;
