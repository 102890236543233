import React, { useState, useEffect } from 'react';
import './TicTacToe.css';
import DullAnimation from './DullAnimation'; // Import DullAnimation component
import Confetti from 'react-dom-confetti'; // Import Confetti component


// Function to calculate the winner
const calculateWinner = (squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
};

const TicTacToe = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [xIsNext, setXIsNext] = useState(true);
  const responses = ["Hmm...", "Let's see...", "Interesting...", "That's a tough one...", "Aha!", "Perfect!", "Maybe this will work...", "Here goes!", "Let's try this...", "Taking a chance!", "Going all in!", "Playing it safe...", "I think I have you there...", "This is going to be fun...", "Just wait and see..."]
  const [winner, setWinner] = useState(null);
  const [isComputersTurn, setIsComputersTurn] = useState(false);
  const [enlargedSquare, setEnlargedSquare] = useState(null); // State to track enlarged square index
  const [lastMoveIndex, setLastMoveIndex] = useState(null);

  useEffect(() => {
    const winner = calculateWinner(board);
    if (winner) {
      setWinner(winner);
    } else if (!board.includes(null)) {
      setWinner('draw');
    } else if (!xIsNext && isComputersTurn) {
      // Delay computer's move
      setTimeout(() => {
        makeScriptMove();
        setIsComputersTurn(false);
      }, 1000); // Adjust the delay time (in milliseconds) as needed
    }
  }, [board, xIsNext, isComputersTurn]);

  const handleClick = (index) => {
    if (winner || board[index]) return;
    const squares = [...board];
    squares[index] = 'X';
    setBoard(squares);
    setXIsNext(false);
    setIsComputersTurn(true);
    setLastMoveIndex(index); // Set the index of the latest move
    setBoard([...squares]); // Trigger re-render
  };
  
  
  const makeScriptMove = () => {
    const squares = [...board];
    let index;
  
    // Implement more advanced logic for computer's move
  
    // After determining the index of the computer's move
    setLastMoveIndex(index); // Set the index of the latest move
  
    // Ensure React re-renders the component after updating the state
    setBoard([...squares]); // Trigger re-render


    // Check for winning move
    for (let i = 0; i < squares.length; i++) {
      if (!squares[i]) {
        squares[i] = 'O';
        if (calculateWinner(squares) === 'O') {
          setBoard(squares);
          setXIsNext(true);
          return;
        }
        squares[i] = null;
      }
    }
  
    // Block user from winning
    for (let i = 0; i < squares.length; i++) {
      if (!squares[i]) {
        squares[i] = 'X';
        if (calculateWinner(squares) === 'X') {
          squares[i] = 'O';
          setBoard(squares);
          setXIsNext(true);
          return;
        }
        squares[i] = null;
      }
    }
  
    // If no winning move or block, play center if available
    if (!squares[4]) {
      squares[4] = 'O';
      setBoard(squares);
      setXIsNext(true);
      return;
    }
  
    // If center is not available, play random move
    do {
      index = Math.floor(Math.random() * 9);
    } while (squares[index]);
    squares[index] = 'O';
    setBoard(squares);
    setXIsNext(true);
  
    // Set the index of the square to be enlarged
    setEnlargedSquare(index);
  
    // Remove the enlargedSquare after a brief delay
    setTimeout(() => {
      setEnlargedSquare(null);
    }, 200);
  };

  const renderSquare = (index) => {
    return (
      <button className={`square ${lastMoveIndex === index ? 'highlighted' : ''}`} onClick={() => handleClick(index)}>
        <span className={`symbol ${lastMoveIndex === index ? 'zoom-in' : ''}`}>
          {board[index]}
        </span>
      </button>
    );
  };
  
  
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 220,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 0,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };
  // Function to handle replay button click
  const handleReplay = () => {
    setBoard(Array(9).fill(null)); // Reset the board
    setWinner(null); // Reset the winner
    setXIsNext(true); // Set X to start
  };
 // Render replay button if winner exists
  const renderReplayButton = () => {
    if (winner) {
      return(
        <button className="replay-button" onClick={handleReplay}>
          <span className="replay-icon">&#x21BA;</span> {/* Replay icon */}
        </button>
      )
    }
  };
  return (
    <div className="tic-tac-toe">
      <div className="status">{winner ? (winner === 'draw' ? `It's a draw!` : (winner === 'X' ? `Winner: ${winner}` : <DullAnimation />)) : `${xIsNext ? 'Your Turn' : responses[Math.floor(Math.random()*responses.length)]}`}</div>
      <div className="board-row">
        {renderSquare(0)}
        {renderSquare(1)}
        {renderSquare(2)}
      </div>
      <div className="board-row">
        {renderSquare(3)}
        {renderSquare(4)}
        {renderSquare(5)}
      </div>
      <div className="board-row">
        {renderSquare(6)}
        {renderSquare(7)}
        {renderSquare(8)}
      </div>
      <Confetti active={winner === 'X'} config={config} />
      {renderReplayButton()} {/* Render replay button */}
    </div>
  );
};

export default TicTacToe;
